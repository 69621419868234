* {
  font-family: "Roboto", sans-serif;
  color: white;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.name::after {
  content: " ";
  background-color: rgb(46, 139, 87);
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.3rem;
}

.overlayDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectCard {
  transition: transform 1s ease !important;
}
.projectCard:hover {
  transform: scale(1.05);
}

.introduction{
  background-color: black;
}
